import { motion, MotionStyle } from "framer-motion";

export const Button: React.FC<{
  title: string;
  handleClick: () => void;
  styles?: MotionStyle;
  className?: string;
  ref?: any;
}> = ({ title, handleClick, styles, className, ref }) => (
  <motion.div
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 1 }}
    onClick={handleClick}
    className={className}
    style={styles}
  >
    <span ref={ref}>{title}</span>
  </motion.div>
);
