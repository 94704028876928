import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";
import "moment/locale/ru";

import "./PriceTablePage.css";
import { QuotaFilter } from "./QuotaFilter";
import { PriceTable } from "./PriceTable";
import { HotelFilterData, PriceDate } from "../interfaces";
import Loading from "../../components/Loading";
import { fetchHotelsForFilter, fetchPriceTable } from "../../services/api";

moment.locale("ru");

const PriceTablePage: React.FC = () => {
  const [choosedHotelIds, setChoosedHotelIds] = useState<number[]>([]);
  const [hotels, setHotels] = useState<HotelFilterData[]>();
  const [dateFrom, setDateFrom] = useState<Date>(new Date());
  const [dateTo, setDateTo] = useState<Date>(new Date());
  const [priceTableData, setPriceTableData] = useState<PriceDate[]>();
  const [isFetchingStarted, setIsFetchingStarted] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const hotelsData = await fetchHotelsForFilter();
      if (hotelsData) {
        setHotels(hotelsData);
      } else {
        alert("Не получилось получить данные по отелям");
      }
    };
    if (!hotels) {
      fetch();
    }
  }, [hotels]);

  const filterQuota = useCallback(async () => {
    if (choosedHotelIds && dateFrom && dateTo) {
      const priceTable = await fetchPriceTable(
        choosedHotelIds,
        dateFrom.toISOString(),
        dateTo.toISOString()
      );

      if (priceTable) {
        setPriceTableData(priceTable);
        setIsFetchingStarted(true);
      } else {
        alert("Не получилось получить данные по ценам");
      }
    } else {
      alert("Заполните все поля");
    }
  }, [choosedHotelIds, dateFrom, dateTo]);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      filterQuota();
    }
  }, [shouldRefresh, filterQuota]);

  return (
    <>
      <QuotaFilter
        dateFrom={dateFrom}
        dateTo={dateTo}
        hotels={hotels}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        setChoosedHotelIds={setChoosedHotelIds}
        filterQuota={filterQuota}
      />
      {priceTableData ? (
        <PriceTable
          priceTableData={priceTableData}
          setShouldRefresh={setShouldRefresh}
        />
      ) : (
        isFetchingStarted && <Loading />
      )}
    </>
  );
};

export { PriceTablePage };
