export class DefaultDict {
  constructor(defaultConstructor: () => any) {
    return new Proxy(
      {},
      {
        get: (target: any, name: string) =>
          name in target ? target[name] : defaultConstructor(),
      }
    );
  }
}
