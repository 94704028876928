import React, { createContext, useState } from "react";

interface AuthContextValue {
  isAuth: boolean;
  signIn: (isAuth: boolean, callback: () => void) => void;
  signOut: (callback: () => void) => void;
}

export const AuthContext = createContext({} as AuthContextValue);

export const AuthProvider: React.FC<{ children: any }> = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const signIn = (isAuth: boolean, callback: () => void) => {
    setIsAuth(isAuth);
    callback();
  };
  const signOut = (callback: () => void) => {
    setIsAuth(false);
    callback();
  };
  const value: AuthContextValue = { isAuth, signIn, signOut };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
