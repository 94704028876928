import React, { forwardRef } from "react";

import DatePicker from "react-datepicker";

import { ru } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Button } from "../../components/Button";
import { QuotaFilterProps } from "../interfaces";

const animatedComponents = makeAnimated();
interface SelectItemValue {
  label: string;
  value: number;
}
const ExampleCustomInput = forwardRef(
  ({ value, onClick, className }: any, ref: any) => (
    <Button
      className={className}
      title={value}
      handleClick={onClick}
      ref={ref}
    />
  )
);

const QuotaFilter: React.FC<QuotaFilterProps> = ({
  dateFrom,
  dateTo,
  hotels,
  setDateFrom,
  setDateTo,
  setChoosedHotelIds,
  filterQuota,
}) => {
  return (
    <div className="QuotaFilterContainer">
      <span>С</span>
      <DatePicker
        locale={ru}
        selected={dateFrom}
        onChange={(date: any) => setDateFrom(date)}
        customInput={<ExampleCustomInput className="DatePickerButton" />}
      />
      <span>По</span>
      <DatePicker
        locale={ru}
        selected={dateTo}
        onChange={(date: any) => setDateTo(date)}
        customInput={<ExampleCustomInput className="DatePickerButton" />}
      />
      <div className="SelectContainer">
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={[]}
          isMulti
          options={hotels?.map((hotel) => {
            return { label: hotel.title, value: hotel.id };
          })}
          // @ts-ignore
          onChange={(values: SelectItemValue[]) =>
            setChoosedHotelIds(values.map((item) => item.value))
          }
        />
      </div>
      <Button
        handleClick={filterQuota}
        title="Найти"
        className="QuotaFilterButton"
      />
    </div>
  );
};

export { QuotaFilter };
