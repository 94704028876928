const NotFoundPage = () => {
  return (
    <div>
      <h2>Мы ничего не нашли!</h2>
      <p>Похоже страница, которую вы ищите, не существует</p>
    </div>
  );
};

export { NotFoundPage };
