import React from "react";

import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../hook/useAuth";

const RequireAuth: React.FC<{ children: any }> = ({ children }) => {
  const location = useLocation();
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
