import React, { useState } from "react";

import { motion } from "framer-motion";

import { Button } from "../../components/Button";

export const PriceChangeModal: React.FC<{
  toggleModal: () => void;
  handleChangePrice: (price: string) => void;
}> = ({ toggleModal, handleChangePrice }) => {
  const [price, setPrice] = useState("");
  return (
    <motion.div
      key="modal"
      exit={{ opacity: 0 }}
      className="PriceChangeModalContainer"
    >
      <div className="PriceChangeInnerContainer">
        <h1>Изменение цены</h1>
        <input
          type="text"
          pattern="[0-9]*"
          className="PriceChangeInput"
          value={price}
          onChange={(e) => e.target.validity.valid && setPrice(e.target.value)}
        />
        <div className="PriceChangeButtonsContainer">
          <Button
            title="Отменить"
            handleClick={toggleModal}
            className="ButtonCancel"
          />
          <Button
            title="Подтвердить"
            handleClick={() => handleChangePrice(price)}
            className="ButtonConfirm"
          />
        </div>
      </div>
    </motion.div>
  );
};
