import Spinner from "react-bootstrap/Spinner";

function Loading() {
  return (
    <div className="Loading">
      <Spinner
        animation="border"
        role="status"
        style={{
          width: "5rem",
          height: "5rem",
          color: "rgb(0, 100, 150, 0.5)",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loading;
