import { useLocation, useNavigate } from "react-router-dom";
import { FloatingLabel, Form } from "react-bootstrap";

import { useAuth } from "../hook/useAuth";
import { authenticate } from "../services/api";

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();

  const fromPage = location.state?.from?.pathname || "/";

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.target;
    const username = form.username.value;
    const password = form.password.value;
    const success = await authenticate(username, password);
    if (success) {
      signIn(success, () => navigate(fromPage, { replace: true }));
    } else {
      form.username.value = "";
      form.password.value = "";
      alert("Введены неправильные данные, попробуйте еще раз");
    }
  };

  return (
    <div className="LoginContainer">
      <Form onSubmit={handleSubmit} className="LoginForm">
        <span style={{ fontSize: 35 }}>Вход</span>
        <br />
        <FloatingLabel controlId="username" label="Логин" className="mb-3">
          <Form.Control type="username" placeholder="Логин" />
        </FloatingLabel>
        <FloatingLabel controlId="password" label="Пароль">
          <Form.Control type="password" placeholder="Пароль" />
        </FloatingLabel>
        <br />
        <input className="LoginButton" type="submit" value="Войти" />
      </Form>
    </div>
  );
};
