import { memo, useCallback, useEffect, useState } from "react";
import { PriceDate, HotelPriceDate, RoomPriceDate } from "../interfaces";
import { DefaultDict } from "../types";
import moment from "moment";
import { WEEKENDS } from "../constants";
import { AnimatePresence } from "framer-motion";
import { PriceChangeModal } from "./PriceChangeModal";
import { Button } from "../../components/Button";
import { updateQuotasPrice } from "../../services/api";

const getKey = (hotelName: string, roomName: string) =>
  `${hotelName}\x00${roomName}`;

export const PriceTable: React.FC<{
  priceTableData: PriceDate[];
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ priceTableData, setShouldRefresh }) => {
  const [choosedQuotaIds, setChoosedQuotaIds] = useState<number[]>([]);
  const [uniqueDates, setUniqueDates] = useState<string[]>([]);
  const [uniqueHotelNames, setUniqueHotelNames] = useState<string[]>([]);
  const [hotelDatePrices, setHotelDatePrices] = useState<HotelPriceDate>({});
  const [hotelRoomNamesMap, setHotelRoomNamesMap] = useState<{
    [key: string]: Set<string>;
  }>({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const uniqueDatesSet: Set<string> = new Set();
    const uniqueHotelNamesSet: Set<string> = new Set();
    const hotelDict: any = new DefaultDict(Array);
    const hotelRoomNameDict: any = new DefaultDict(Array);
    for (const priceDate of priceTableData) {
      hotelDict[getKey(priceDate.hotel_title, priceDate.room_category_name)] = [
        ...hotelDict[
          getKey(priceDate.hotel_title, priceDate.room_category_name)
        ],
        {
          id: priceDate.id,
          date: priceDate.date,
          price: priceDate.price_value,
          roomName: priceDate.room_category_name,
          hotelName: priceDate.hotel_title,
        },
      ];

      hotelRoomNameDict[priceDate.hotel_title] = new Set([
        priceDate.room_category_name,
        ...hotelRoomNameDict[priceDate.hotel_title],
      ]);
      uniqueDatesSet.add(priceDate.date);
      uniqueHotelNamesSet.add(priceDate.hotel_title);
    }

    setUniqueDates(Array.from(uniqueDatesSet));
    setUniqueHotelNames(Array.from(uniqueHotelNamesSet));
    setHotelDatePrices(hotelDict);
    setHotelRoomNamesMap(hotelRoomNameDict);
  }, [priceTableData]);

  const handleClick = useCallback((quotaId: number, isClicked: boolean) => {
    if (isClicked) {
      setChoosedQuotaIds((prev) => prev.filter((id) => id !== quotaId));
    } else {
      setChoosedQuotaIds((prev) => [...prev, quotaId]);
    }
  }, []);

  const toggleModal = useCallback(() => setIsModalVisible((prev) => !prev), []);

  const handleChangePrice = useCallback(
    async (price: string) => {
      // @ts-ignore
      if (!isNaN(price)) {
        await updateQuotasPrice(choosedQuotaIds, Number(price));
        setShouldRefresh(true);
        setChoosedQuotaIds([]);
        toggleModal();
      } else {
        alert("Введите число!");
      }
    },
    [choosedQuotaIds]
  );

  return (
    <>
      <div>
        <Button
          className="ChangePriceButton"
          handleClick={toggleModal}
          title="Изменить цену"
        />
      </div>
      <div className="PriceTableContainer">
        <table>
          {uniqueHotelNames.map((hotelName: string) => {
            const hotelNameCell = (
              <DummyCell className="HotelNameCell" title={hotelName} />
            );
            const dateCells = <DatesRow dates={uniqueDates} />;
            const hotelRooms = Array.from(hotelRoomNamesMap[hotelName]).map(
              (roomName: string) => {
                const roomNameCell = (
                  <DummyCell title={roomName} className="RoomNameCell" />
                );
                const prices = hotelDatePrices[getKey(hotelName, roomName)].map(
                  (roomPriceDate: RoomPriceDate) => {
                    const isClicked = choosedQuotaIds.includes(
                      roomPriceDate.id
                    );
                    return (
                      <RenderCategoryRow
                        roomPriceDate={roomPriceDate}
                        isClicked={isClicked}
                        handleClick={() =>
                          handleClick(roomPriceDate.id, isClicked)
                        }
                      />
                    );
                  }
                );
                return <tr>{[roomNameCell, ...prices]}</tr>;
              }
            );
            return (
              <>
                <tr>{[hotelNameCell, dateCells]}</tr>
                <>{[...hotelRooms]}</>
              </>
            );
          })}
        </table>
        <AnimatePresence>
          {isModalVisible && (
            <PriceChangeModal
              toggleModal={toggleModal}
              handleChangePrice={handleChangePrice}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

const DummyCell: React.FC<{ title: string; className: string }> = ({
  title,
  className,
}) => (
  <td>
    <div className={className}>{title}</div>
  </td>
);

const DatesRow: React.FC<{ dates: string[] }> = memo(({ dates }) => {
  const dateCells = dates.map((date) => {
    const dateMoment = moment(date);
    const dateOfWeek = dateMoment.format("dd");
    const isWeekendCell = WEEKENDS.indexOf(dateOfWeek) !== -1;
    return (
      <td key={date}>
        <div className={isWeekendCell ? "WeekendCell" : "OrdinaryDateCell"}>
          <span>{dateMoment.format("D")}</span>
          <span>{dateOfWeek}</span>
        </div>
      </td>
    );
  });
  return <>{dateCells}</>;
});

const RenderCategoryRow: React.FC<{
  roomPriceDate: RoomPriceDate;
  isClicked: boolean;
  handleClick: () => void;
}> = ({ roomPriceDate, isClicked, handleClick }) => {
  const isPriceSet = Boolean(roomPriceDate.price);
  return (
    <td key={`${roomPriceDate.id}`}>
      <Button
        handleClick={handleClick}
        className="PriceCell"
        styles={{
          border: `${
            isClicked
              ? "solid rgb(255, 0, 0, 0.5)"
              : "dashed rgb(0, 0, 255, 0.5)"
          }`,
          fontSize: isPriceSet ? 20 : 14,
        }}
        title={isPriceSet ? roomPriceDate.price.toString() : "Не установлена"}
      />
    </td>
  );
};
