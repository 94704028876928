import { HotelFilterData, PriceDate } from "../pages/interfaces";

const JSON_HEADERS = { "Content-Type": "application/json" };
const API_URL = process.env.REACT_APP_API_URL;
const AUTH_URL = process.env.REACT_APP_AUTH_URL;

export const fetchPriceTable = async (
  hotel_ids: number[],
  date_from: string,
  date_to: string
): Promise<PriceDate[] | undefined> => {
  try {
    const response = await fetch(`${API_URL}/get_price_table`, {
      method: "POST",
      credentials: "include",
      headers: JSON_HEADERS,
      body: JSON.stringify({ hotel_ids, date_from, date_to }),
    });
    return await response.json();
  } catch {}
  return undefined;
};

export const fetchHotelsForFilter = async (): Promise<
  HotelFilterData[] | undefined
> => {
  try {
    const response = await fetch(`${API_URL}/price_table_hotels`, {
      method: "GET",
      credentials: "include",
      headers: JSON_HEADERS,
    });
    if (response.status === 200) {
      return await response.json();
    }
  } catch {}
  return undefined;
};

export const updateQuotasPrice = async (quota_ids: number[], price: number) => {
  const response = await fetch(`${API_URL}/update_price_table`, {
    method: "POST",
    credentials: "include",
    headers: JSON_HEADERS,
    body: JSON.stringify({ quota_ids, price }),
  });
  return await response.json();
};

export const authenticate = async (
  username: string,
  password: string
): Promise<boolean> => {
  try {
    const response = await fetch(`${AUTH_URL}/manager/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      credentials: "include",
      headers: JSON_HEADERS,
    });
    if (response.status === 200) {
      return true;
    }
  } catch {}
  return false;
};
